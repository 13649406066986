import React, {useState, useEffect} from "react";
import {message} from "antd";
import ReactQuill from "./quill";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import {Modal} from "antd";
import {Autocomplete, Alert} from "@material-ui/lab";
import "antd/dist/antd.css";

import "./App.scss";
import "react-quill/dist/quill.snow.css"; // ES6

const editOptions = {
  toolbar: [
    [
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "code-block",
      "link",
      "image",
      "clean",
    ],
    [{header: [1, 2, 3, 4, 5, 6, false]}, {font: []}],
    [
      {list: "ordered"},
      {list: "bullet"},
      {align: []},
      {script: "sub"},
      {script: "super"},
      {indent: "-1"},
      {indent: "+1"},
      {color: []},
      {background: []},
    ],
  ],
};

interface Result {
  stage: "EMAIL_SEND" | "EMAIL_DELETE";
  success: boolean;
}

interface Person {
  is_group: false;
  email: string;
}

interface Group {
  is_group: true;
  email: string;
  members: User[];
}

type User = Person | Group;

const baseUrl = "/api";

function getEmails(user: User): string[] {
  return user.is_group ? user.members.map((m) => m.email) : [user.email];
}

function WarningMessage({user}: {user: User | null}) {
  if (user == null) return <></>;
  return (
    <Alert severity="warning" className="email-list">
      The email will be sent to <b>{getEmails(user).join(", ")}</b>
    </Alert>
  );
}

async function call(path: string, body: Object): Promise<Object> {
  try {
    const response = await fetch(path, {
      method: "POST",
      body: JSON.stringify(body),
    });
    if (response.status / 100 != 2) {
      const output = await response.text();
      throw `${output}`;
    }
    const out = await response.json();
    if ("data" in out) return out.data;
    throw `${out.message}<br/>${out.internal_message || ""}`;
  } catch (e) {
    message.error(`${e}`);
  }
  throw "terminate any running method";
}

function ResultModal({data}: {data: Result[]}) {
  const text = (state: "EMAIL_SEND" | "EMAIL_DELETE") => {
    if (state == "EMAIL_SEND") {
      return "Email sent.";
    }
    if (state == "EMAIL_DELETE") {
      return "Email cleaned from trash.";
    }
  };
  return (
    <Modal
      visible={data.length > 0}
      closable={false}
      okButtonProps={{style: {display: "none"}}}
      cancelButtonProps={{style: {display: "none"}}}>
      <div className="modal">
        {(data || []).map((result) => {
          return (
            <div className={`status-${result.success}`}>
              <Typography style={{display: "inline"}}>
                {text(result.stage)}
              </Typography>
            </div>
          );
        })}
      </div>
    </Modal>
  );
}

function App() {
  const [html, setHtml] = useState("");
  const [subject, setSubject] = useState("");
  const [users, setUsers] = useState<User[]>([
    {
      email: "founders@even.in",
      is_group: true,
      members: [
        {
          email: "mayank@even.in",
          is_group: false,
        },
        {
          email: "ale@even.in",
          is_group: false,
        },
        {
          email: "mati@even.in",
          is_group: false,
        },
      ],
    } as Group,
  ]);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<Result[]>([]);

  // useEffect(() => {
  //   call(`${baseUrl}/getMembers`, {}).then((obj) => {
  //     setUsers((obj as {members: User[]})?.members);
  //   });
  // }, []);

  const sendEmail = async () => {
    setLoading(true);
    try {
      const payload = {to_addr: getEmails(user!), subject, html};
      const result = await call(`${baseUrl}/sendEmail`, payload);
      setModal((result as {results: Result[]}).results);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <ResultModal data={modal} />
      <Typography variant="h2">Anonymous feedback form</Typography>
      <Grid
        className="outer-container"
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}>
        <Grid item lg={7} md={8} sm={10} xs={11}>
          <Autocomplete
            id="to-addr"
            noOptionsText="Loading users..."
            options={users}
            getOptionLabel={(opt) => opt.email}
            groupBy={(opt) => (opt.is_group ? "Groups" : "Users")}
            renderInput={(params) => <TextField {...params} label="Send to:" />}
            onChange={(_, user) => setUser(user)}
          />
        </Grid>
        <Grid item lg={7} md={8} sm={10} xs={11}>
          <TextField
            className="subject"
            value={subject}
            onChange={(ev) => setSubject(ev.currentTarget.value)}
            label="Subject:"
          />
        </Grid>
        <Grid item lg={7} md={8} sm={10} xs={11}>
          <ReactQuill value={html} onChange={setHtml} modules={editOptions} />
        </Grid>
        <Grid item lg={7} md={8} sm={10} xs={11}>
          <WarningMessage user={user} />
          <br />
          <Button onClick={sendEmail} disabled={!user}>
            {loading ? <CircularProgress /> : <>Send Email</>}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
